@import '../../../sass/base.scss';

#man_alloc_modal {
  .man_alloc_modal_desc {
    margin-bottom: 0;
  }
  .man_alloc_form {
    .man_alloc_modal_label {
      margin-bottom: 0.75rem;
    }
  }
  .form_footer {
    @media (max-width: $laptop_breakpoint) {
      #man_alloc_cancel_btn,
      #man_alloc_save_btn,
      #man_alloc_save_changes_btn,
      #man_alloc_remove_recipient_btn {
        width: 100%;
      }
      #man_alloc_cancel_btn,
      #man_alloc_remove_recipient_btn {
        height: 2.125rem;
        padding: 0;
      }
    }
  }
}
