.event_tutorial {
  width: 90vw;
  height: 162px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  .tutorial_img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .tutorial_intro {
    padding: 26px 17px;
    width: calc(100% - 113px);
    .tutorial_heading {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: 500;
    }
    p {
      font-size: 0.8125rem;
      line-height: 0.8125rem;
      color: #666666;
      margin-bottom: 13px;
      opacity: 0.88;
    }
    .tutorial_btn {
      background-color: #117bf6;
      color: #ffffff;
      border-radius: 5px;
      border: none;
      padding: 10px 23px;
      max-width: 127px;
      font-size: 0.8125rem;
      line-height: 0.875rem;
      float: right;
      &:focus {
        outline-offset: 3px;
      }
      &:hover {
        cursor: pointer;
        background-color: #065b8d;
      }
    }
    @media (max-width: 300px) {
      padding-top: 20px;
      .tutorial_btn {
        padding: 10px 17px;
      }
    }
  }
  @media (min-width: 499px) {
    width: 343px;
  }
}
