@import '../../../sass/base.scss';

.verified_accounts_modal {
  text-align: center;
  img {
    margin-bottom: 1.25rem;
  }
  span {
    margin-bottom: 1.25rem;
    display: block;
    padding: 0 3.125rem;
  }
  .verified_button_container {
    display: flex;
    div {
      width: 50%;
      &:first-child {
        margin-right: 10px;
      }
    }
    .logout_button {
      font-size: 0.875rem;
      line-height: 0.875rem;
      text-align: center;
      color: #117bf6;
      background-color: #fff;
      border: 0.125rem solid #117bf6;
      padding: 0.625rem 0;
      width: 100%;
      border-radius: 0.3125rem;
      cursor: pointer;
      &:hover {
        background-color: #ececec;
        color: #065b8d;
        border: 0.125rem solid #065b8d;
      }
    }
    .resend-button {
      font-size: 0.875rem;
      line-height: 0.875rem;
      text-align: center;
      color: #fff;
      background-color: #117bf6;
      border: 0.125rem solid #117bf6;
      padding: 0.625rem 0;
      width: 100%;
      border-radius: 0.3125rem;
      cursor: pointer;
      &:hover {
        background-color: #ececec;
        color: #065b8d;
        border: 0.125rem solid #065b8d;
      }
      &:disabled,
      &:disabled &:hover {
        background-color: #999;
        border: 0.125rem solid #999;
        color: #fff;
        cursor: unset;
      }
    }
  }
  @media (max-width: $tablet_breakpoint) {
    span {
      padding: 0;
    }
    .verified_button_container {
      display: block;
      div {
        width: 100%;
        &:first-child {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }
  }
}
