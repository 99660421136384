.hamburger_menu {
  display: inline-block;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  .icon_bar_1,
  .icon_bar_2,
  .icon_bar_3 {
    width: 18px;
    height: 2px;
    background-color: #ffffff;
    margin: 0 0 3px;
  }
  .icon_bar_3 {
    margin-bottom: 0;
  }
}

.event_refresh_btn {
  padding: 0;
  background-color: transparent;
  height: 32px;
  border: 0;
  cursor: pointer;
}
