// colors
$background_color: #e5e5e5;
$primary_color: #057aff;
$primary_btn_color: #117bf6;

// screen breakpoints
$mobile_breakpoint: 320px;
$tablet_breakpoint: 481px;
$laptop_breakpoint: 769px;
$dekstop_breakpoint: 1025px;
$tv_breakpoint: 1201px;
$ipad_breakpoint: 820px;
$ipad_mini_breakpoint: 767px;
$rare_laptop_breakpoint: 535px;
$rare_mobile_breakpoint: 360px;
