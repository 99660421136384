@import '../../../sass/base.scss';

p.no_event_text {
  color: #333333;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin-top: 1rem;
  @media (max-width: $rare_mobile_breakpoint) {
    line-height: 1.2rem;
  }
}
