.sl_cont {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 3.875rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5e5;
  z-index: 2;
  padding-top: 2.5rem;
}
