#manage_list_btn {
  padding: 0.3125rem;
  font-weight: 500;
  border: 0.125rem solid #117bf7;
  background-color: #ffffff;
  border-radius: 0.313rem;
  color: #117bf7;
  font-size: 0.875rem;
  line-height: 0.875rem;
  float: right;
  &.idle {
    display: block;
    margin-left: auto;
  }
  &.inline {
    float: right;
  }
  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: #ececec;
    color: #065b8d;
    border: 0.125rem solid #065b8d;
  }
  &:disabled {
    border: 0.125rem solid #999999;
    color: #999999;
  }
}
