@import '../../../sass/base';
.event_card_container {
  max-width: 738px;
  margin-top: 15px;
  height: 178px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 20%);
  margin-left: auto;
  margin-right: auto;
  .event_header_cont {
    height: 63%;
  }
  .event_card_footer {
    background-color: #ffffff;
    height: 37%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    .invite_btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      background-color: $primary_color;
      border-radius: 5px;
      border-width: 0;
      max-width: 700px;
      width: 95%;
      padding: 10px;
      font-family: 'Proxima Nova';
      cursor: pointer;
      font-weight: 700;
      font-size: 0.8125rem;
      line-height: 0.8125rem;
      @media (max-width: $tablet_breakpoint) {
        font-size: 0.8rem;
      }
      &:focus {
        outline-offset: 3px;
      }
      &:hover,
      &:active {
        background-color: #065b8d;
      }
    }
  }
}
@media (max-width: $tablet_breakpoint) {
  .event_header_bottom {
    flex-direction: column;
    height: 42%;
  }
}
