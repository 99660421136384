.invalid_save_modal {
  text-align: center;
  padding: 1rem 0;
  img {
    margin-bottom: 1.25rem;
  }
  span {
    margin-bottom: 1.25rem;
    display: block;
    padding: 0 3.125rem;
  }
  .view_tickets_btn {
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-align: center;
    color: #117bf6;
    background-color: #fff;
    border: 0.125rem solid #117bf6;
    padding: 0.625rem 0;
    width: 100%;
    border-radius: 0.3125rem;
    cursor: pointer;
    &:hover {
      background-color: #ececec;
      color: #065b8d;
      border: 0.125rem solid #065b8d;
    }
  }
}
