.row_section_header_cont {
  margin-bottom: 1.313rem;
  .row_label_cont {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5625rem;
    .row_section_label {
      font-size: 0.875rem;
      line-height: 0.875rem;
      color: #000000;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0.438rem;
    }
  }
  .share_all {
    display: flex;
    margin-bottom: 1.5625rem;
    align-items: center;
    .share_all_text {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
    }
  }
}
.nonforwardable_tix_header {
  margin-top: 1.563rem;
}
.nonvenue_tix_header {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}
