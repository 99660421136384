.svg-indicator {
  transform: rotate(-90deg);
}

.svg-indicator-track,
.svg-indicator-indication {
  fill: transparent;
}

.svg-indicator-track {
  stroke: rgba(0, 0, 0, 0.3);
}

.svg-indicator-indication {
  stroke: #fff;
  stroke-linecap: round;
  transition: stroke-dashoffset 3s;
}

.progress-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
}
