.toast.modal_overlay {
  .toast.modal_dialog {
    width: 300px;
    max-width: unset;
    text-align: center;
    img {
      width: 40px;
    }
    output {
      display: block;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0 auto;
      margin-top: 15px;
    }
  }
}
