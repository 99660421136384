.select_all_tix_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 1.9375rem;
  margin-bottom: 1.5625rem;
  &.cancel_only {
    justify-content: end;
    margin-bottom: 0;
    height: 1.75rem;
    float: right;
  }
  #cancel_select_all_tix_btn {
    width: 5.625rem;
    height: 1.75rem;
    font-weight: 500;
    border: 0.125rem solid #117bf7;
    background-color: #ffffff;
    border-radius: 0.313rem;
    color: #117bf7;
    font-size: 0.875rem;
    line-height: 0.875rem;
    &:hover {
      cursor: pointer;
      background-color: #ececec;
      color: #065b8d;
      border: 2px solid #065b8d;
    }
  }
}
