@import '_variables.scss';

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  background-color: $background_color;
}

button:focus {
  outline-offset: 3px;
}

label {
  height: fit-content;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.modal_close {
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

img.modal_close_icon {
  width: 15.36px;
  height: 14.86px;
}

.add_modal_footer,
.modal_footer {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  #modal_cancel_btn,
  #save_and_view_btn {
    width: 45.665%;
    height: 2.125rem;
    background-color: #ffffff;
    color: $primary_btn_color;
    border-radius: 0.3125rem;
    border: 0.125rem solid #117bf7;
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    &:enabled:hover {
      background-color: #ececec;
      color: #065b8d;
      border: 0.125rem solid #065b8d;
    }
  }
  #modal_save_btn,
  #save_and_add_btn,
  #save_recipient_changes_btn,
  #man_alloc_save_btn,
  #man_alloc_save_changes_btn,
  #invalid_email_modal_footer_right_btn {
    height: 2.125rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    width: 53.195%;
    background: $primary_btn_color;
    border-radius: 0.3125rem;
    color: #ffffff;
    border: none;
    cursor: pointer;
    &:disabled {
      background-color: #999;
      cursor: not-allowed;
    }
    &:enabled:hover {
      background-color: #065b8d;
    }
  }
  #remove_recipient_btn,
  #man_alloc_cancel_btn,
  #man_alloc_remove_recipient_btn,
  #invalid_email_modal_footer_left_btn {
    color: #df0303;
    width: 45.665%;
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    padding: 0.25rem 3.75rem;
    background: #ffffff;
    border: 0.125rem solid #df0303;
    border-radius: 0.3125rem;
    cursor: pointer;
    &:hover {
      color: #900000;
      background: #ececec;
      border: 0.125rem solid #910000;
      border-radius: 0.3125rem;
    }
  }
  @media (max-width: $laptop_breakpoint) {
    #modal_cancel-btn,
    #modal_save_btn,
    #save_and_add_btn,
    #save_and_view_btn,
    #remove_recipient_btn,
    #save_recipient_changes_btn,
    #invalid_email_modal_footer_left_btn,
    #invalid_email_modal_footer_right_btn {
      font-size: 0.8rem;
    }
    &.form_footer {
      flex-direction: column;
      gap: 0.625rem;
      #save_and_add_btn,
      #save_and_view_btn,
      #remove_recipient_btn,
      #save_recipient_changes_btn,
      #invalid_email_modal_footer_left_btn,
      #invalid_email_modal_footer_right_btn {
        width: 100%;
        height: 2.125rem;
        padding: 0;
      }
    }
  }
}

@media (max-width: $tablet_breakpoint) {
  * {
    font-size: 0.9rem;
  }
}
