.invalid_email_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.938rem;
  #invalid_email_modal_title {
    margin-top: 0;
    margin-bottom: 0;
  }
}
