@import '../../../sass/base.scss';

.seat_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #333333;
  .ticket_seat {
    width: 4.0625rem;
    span {
      display: block;
    }
    .ticket_badge {
      padding: 0.125rem 0.3125rem 0.125rem 0.1875rem;
      background: #d9d9d9;
      border-radius: 0.3125rem;
      display: inline-block;
      font-style: italic;
      font-size: 0.75rem;
      line-height: 0.75rem;
      margin-top: 0.25rem;
      margin-right: 0.125rem;
    }
  }
  &.shared {
    gap: 0.8rem;
    .ticket_assigned_cont {
      width: 86%;
    }
    #sharedtix_recall_btn,
    #sharedtix_cancel_btn,
    #sharedtix_view_btn,
    #sharedtix_fix_btn {
      color: #666666;
      padding: 0.625rem 0.9375rem;
      height: min-content;
      font-size: 0.875rem;
      line-height: 0.875rem;
      font-weight: 500;
      background: #ffffff;
      border: 0.5px solid #e0e0e0;
      border-radius: 4px;
      cursor: pointer;
    }
    @media (max-width: $rare_mobile_breakpoint) {
      flex-direction: column;
      align-items: flex-start;
      .ticket_assigned_cont {
        width: 100%;
      }
    }
  }
  .ticket_assigned_cont {
    display: flex;
    justify-content: space-between;
    height: 3.813rem;
    width: 91.33%;
    background-color: #f7f7f7;
    padding: 0.938rem 0.938rem;
    overflow: hidden;
    .ticket_info {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ticket_name {
      font-weight: 700;
    }
    #ticket_edit_btn {
      border-width: 0;
      padding: 0;
      cursor: pointer;
    }
    @media (max-width: $ipad_mini_breakpoint) {
      .ticket_email {
        width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ticket_cont {
    height: 3.813rem;
    width: 91.33%;
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.938rem;
    .unassignable_text {
      font-style: italic;
      color: #666666;
    }
    #manual_alloc_btn {
      border: none;
      padding: 0;
      height: 2rem;
      cursor: pointer;
    }
  }
  @media (max-width: $laptop_breakpoint) {
    font-size: 0.75rem;
    gap: 1.25rem;
    .ticket_cont {
      width: 83%;
    }
  }
}
