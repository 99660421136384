@import '../../../sass/base.scss';

.shared_ticket_modal,
.remove_tix_modal {
  background-color: #ffffff;
  width: 90%;
  max-width: 40.063rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.688rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
  position: relative;
  padding: 1.063rem 1.031rem;
  display: none;
  .warning_icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.938rem;
  }
  #bulk_recall_modal_close_btn,
  #recall_modal_close_btn,
  #cancel_modal_close_btn,
  #remove_tix_modal_close_btn {
    position: absolute;
    right: 1.031rem;
  }
  .modal_text {
    text-align: center;
  }
  .shared_tix_modal_footer,
  .remove_tix_modal_footer {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    #bulk_tix_recall_confirm_btn,
    #bulk_tix_recall_cancel_btn,
    #recall_tix_cancel_btn,
    #cancel_tix_cancel_btn,
    #tix_recall_confirm_btn,
    #tix_cancel_confirm_btn,
    #tix_keep_trying_btn,
    #tix_cancel_share_btn,
    #remove_tix_modal_go_back_btn,
    #remove_tix_modal_remove_btn {
      max-width: 18.75rem;
      width: 100%;
      height: 2.125rem;
      background: #ffffff;
      cursor: pointer;
      border-radius: 5px;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 0.875rem;
      border: 2px solid #117bf7;
      color: #117bf7;
    }
    #bulk_tix_recall_cancel_btn,
    #recall_tix_cancel_btn,
    #cancel_tix_cancel_btn,
    #tix_keep_trying_btn,
    #remove_tix_modal_go_back_btn {
      border: 2px solid #117bf7;
      color: #117bf7;
      &:hover {
        background-color: #ececec;
        color: #065b8d;
        border: 2px solid #065b8d;
      }
    }
    #bulk_tix_recall_confirm_btn,
    #tix_recall_confirm_btn,
    #tix_cancel_confirm_btn,
    #tix_cancel_share_btn,
    #remove_tix_modal_remove_btn {
      color: #df0303;
      border: 2px solid #df0303;
      &:hover {
        color: #900000;
        background: #ececec;
        border: 2px solid #910000;
      }
    }
  }
  @media (max-width: $ipad_mini_breakpoint) {
    .shared_tix_modal_footer,
    .shared_tix_modal_footer.shared_modal_view {
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
    }
  }
  &.opened {
    display: block;
  }
  &.loading {
    text-align: center;
  }
}
