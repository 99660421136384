.search_wrapper {
  position: relative;
  overflow: auto;
  max-height: 6.25rem;
  transition: max-height 0.3s linear;
  transition-delay: 0.15s;
  &.closed {
    max-height: 0;
    transition: max-height 0.3s linear;
    .search_container {
      top: -4.40625rem;
      opacity: 0;
      transition: top 0.3s linear, opacity 0.4s ease-out;
      transition-delay: 0.15s;
    }
  }
  .search_container {
    padding: 1.25rem 1.25rem 0 1.25rem;
    top: 0;
    opacity: 1;
    transition: top 0.3s linear, opacity 0.4s ease-in;
    position: relative;
    input {
      border: 0.0625rem solid #ced4da;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      padding: 0.4375rem 0.8125rem;
      margin-right: 0.625rem;
      min-width: 18.75rem;
    }
    button {
      background-color: #117bf6;
      color: #fff;
      font-size: 0.875rem;
      padding: 0.4375rem 1rem;
      border-radius: 0.25rem;
      border: none;
      cursor: pointer;
      &:hover,
      &:active {
        background-color: #065b8d;
      }
      &:disabled {
        background-color: #999999;
        cursor: unset;
      }
    }
  }
  .result_text {
    padding: 1.5625rem 1.25rem;
    span {
      font-size: 0.875rem;
      font-weight: 700;
    }
    img {
      position: relative;
      top: 0.1875rem;
      left: 0.3125rem;
      cursor: pointer;
    }
  }
}

.no_search_results {
  text-align: center;
  p {
    margin-bottom: 1.5625rem;
    font-weight: 700;
    font-size: 0.8125rem;
    line-height: 0.8125rem;
  }
  img {
    width: 3.75rem;
    margin-bottom: 1.5625rem;
  }
}
