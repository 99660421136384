@import '../../sass/base.scss';

.modal_overlay {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
  &.opened {
    display: block;
  }
  .add_modal_dialog,
  .modal_dialog {
    position: relative;
    display: none;
    z-index: 11;
    max-width: 40.0625rem;
    width: 90%;
    margin: 5.6875rem auto;
    background: #fefefe;
    border: #333333 solid 0rem;
    border-radius: 0.625rem;
    padding: 0.9375rem 0.9375rem;
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.3);
    .modal-switcher {
      background: #d9d9d9;
      border-radius: 0.3125rem;
      .modal-selection {
        display: inline-block;
        width: 50%;
        text-align: center;
        box-sizing: border-box;
        padding: 0.5rem 0;
        font-size: 0.875rem;
        font-weight: 500;
        border: none;
        border-radius: 0.3125rem;
        background-color: #d9d9d9;
        cursor: pointer;
        &.active {
          border-radius: 0.3125rem;
          background: #fff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
          border-radius: 0.3125rem;
          cursor: unset;
        }
      }
    }
    &.opened {
      display: block;
    }
    &.person {
      .add_modal_header,
      #modal_desc,
      .add_person_form {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
      .add_person_form {
        padding-bottom: 0.625rem;
      }
    }
    .add_modal_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.5rem;
      margin-bottom: 1rem;
      #people_modal_title {
        color: #333333;
      }
    }
    p {
      margin-top: 0;
      height: 2rem;
      margin-right: 1.875rem;
      line-height: 1rem;
      @media (max-width: $laptop_breakpoint) {
        margin-right: 0;
        font-size: 0.9rem;
      }
    }
    small {
      text-align: center;
      display: block;
      color: #827474;
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
    #tix_exceeding_error {
      color: #db2727;
    }
    .add_modal_body {
      text-align: center;
      max-width: 38.125rem;
      margin-top: 1em;
      overflow-y: hidden;
      overflow-x: auto;
    }
    @media (max-width: $tablet_breakpoint) {
      margin-top: 12.6rem;
      #modal_close_btn img {
        width: 80%;
      }
    }
    @media (max-width: $rare_mobile_breakpoint) {
      #modal_desc {
        font-size: 0.85rem;
        margin-bottom: 1.5rem;
      }
    }
    .ticket-counts {
      position: absolute;
      bottom: 4.6875rem;
      right: 2.1875rem;
      z-index: 1;
      color: #fff;
      font-size: 0.875rem;
      font-weight: 400;
      background-color: #008605;
      padding: 0.25rem 1rem;
      border-radius: 10.0313rem;
      line-height: normal;
      &.ticket-counts-error {
        background-color: #860000;
      }
    }
  }
  .people-validation {
    padding: 0.625rem;
    border: 0.0625rem solid #df0303;
    border-radius: 0.3125rem;
    text-align: left;
    margin: 0 0.9375rem 1.25rem 0;
    .warning-icon {
      margin-bottom: -0.1875rem;
    }
    span {
      color: #df0303;
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0.625rem;
      line-height: 0.875rem;
    }
  }
}
