@import '../../sass/base.scss';

.ticket_display_main {
  display: flex;
  gap: 1.25rem;
  max-width: 72.5rem;
  width: 96%;
  margin: 1.813rem auto;
  .tickets_section {
    width: 90%;
    max-width: 49.375rem;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    .tickets_section_header {
      border-radius: 10px 10px 0px 0px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      .tablist_cont {
        height: 2.313rem;
        background-color: white;
        padding-left: 1.25rem;
      }
      .event_header_cont {
        height: 6.875rem;
      }
    }
    .tickets_section_body {
      padding: 1.875rem 1.25rem;
    }
    .ticket_count_by_status,
    .ticket_count_by_row {
      color: #666;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  @media (max-width: $ipad_breakpoint) {
    .tickets_section {
      margin-left: auto;
      margin-right: auto;

      .tablist_cont {
        padding-left: 0 !important;
      }
    }
  }
  @media (max-width: $tablet_breakpoint) {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    .tickets_section {
      width: 100%;
      padding-top: 1rem;
      .event_header_cont {
        margin-bottom: 0.938rem;
        .mobile {
          width: 94%;
          margin-left: auto;
          margin-right: auto;
          border-radius: 10px;
          .event_date {
            font-size: 0.8rem;
            line-height: 0.8rem;
            top: 0.9rem;
          }
          .event_teams {
            font-size: 1.1rem;
            line-height: 1.1rem;
            top: 0.8125rem;
          }
        }
      }
      .tickets_section_body {
        padding: 8% 3% 18%;
      }
    }
  }
}
