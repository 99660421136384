@import '../../../sass/base.scss';

.shared_tix_cont {
  margin-bottom: 2.75rem;
  .status_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
    margin-top: 0.78125rem;
    margin-bottom: 0.78125rem;
    .status_text {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 0.875rem;
      margin-bottom: 0;
      margin-top: 0;
    }
    .status_hr {
      flex: 1;
      border: 1px solid #aca4a4;
    }
  }
  .row_label_cont {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5625rem;
    .row_section_label {
      font-size: 0.875rem;
      line-height: 0.875rem;
      color: #000000;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0.438rem;
    }
  }
  .no_tickets_cont {
    text-align: center;
    .no_tickets {
      text-align: center;
      font-family: Proxima Nova;
      font-size: 13px;
      font-weight: 700;
      line-height: 13px;
      letter-spacing: 0px;
      margin-bottom: 5px;
      margin-top: 0;
      a {
        color: #117bf6;
        text-decoration: underline;
      }
    }
    .no_tickets_logo {
      width: 60px;
      margin-top: 20px;
      opacity: 50%;
    }
  }
}
