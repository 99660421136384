@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/proximanova-regular-webfont.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('./assets/fonts/proximanova-bold-webfont.woff') format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Proxima Nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
