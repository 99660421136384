@import '../../../sass/base.scss';

.tickets_tab_list {
  display: flex;
  list-style: none;
  cursor: pointer;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  gap: 0.938rem;
  .tab_header {
    display: inline-block;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 0.938rem;
    line-height: 0.938rem;
    color: #333333;
    height: 100%;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    a {
      color: #333333;
      cursor: pointer;
    }
    .error_dot {
      width: 0.313rem;
      height: 0.313rem;
      background-color: #e40000;
      position: absolute;
      border-radius: 50%;
      margin-left: 0.313rem;
    }
    &.active_tab {
      border-bottom: 0.125rem solid #087abc;
    }
    &.refresh_tab {
      margin-left: auto;
      padding: 0.3375rem 0 0.625rem 0;
      #tix_refresh_btn {
        border: 0;
        background-color: transparent;
        font-size: 0.938rem;
        font-weight: 700;
        font-family: 'Proxima Nova';
        color: #333;
        cursor: pointer;
        .tix_refresh_icon {
          margin-left: 0.438rem;
          &.refreshing {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
            -webkit-transition-duration: 2s;
            -moz-transition-duration: 2s;
            -o-transition-duration: 2s;
            transition-duration: 2s;
          }
        }
        &:disabled {
          color: #999999;
          cursor: default;
          .tix_refresh_icon {
            filter: opacity(0.5);
          }
        }
      }
    }
    &.search_tab {
      margin-right: 1.4375rem;
      padding: 0.4375rem 0 0.625rem 0;
      div {
        div {
          display: inline-block;
          svg {
            margin-left: 0.4375rem;
            margin-bottom: -0.125rem;
          }
        }
      }
    }
  }
  @media (max-width: $ipad_breakpoint) {
    gap: 0.438rem;
    .tab_header {
      width: 50%;
      text-align: center;
      color: #666666;
      &.active_tab {
        border-width: 0.25rem;
        color: #191919;
      }
      &.refresh_tab {
        width: auto;
        padding-top: 0.4375rem;
        span {
          display: none;
        }
      }
      &.search_tab {
        padding: 0;
        width: 2rem;
        margin-right: 0.375rem;
        div {
          margin-top: 0.0625rem;
          div {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            align-items: center;
            display: flex;
            svg {
              margin: 0 auto;
              path {
                fill: #666;
              }
            }
          }
        }
        &.active_tab {
          border: none;
          div {
            div {
              background-color: #087abc;
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
        span {
          display: none;
        }
      }
    }
  }
}
