@import '../../sass/base.scss';
.event_page_cont {
  display: flex;
  max-width: 1100px;
  width: 90vw;
  margin: 24px auto;
  gap: 20px;
  .events_col {
    max-width: 738px;
    width: 100%;
    margin: 0 auto;
    .events_col_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.125rem;
      }
    }
  }
  @media (max-width: $dekstop_breakpoint) {
    flex-direction: column;
  }
}
