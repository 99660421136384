@import '../../../sass/base.scss';

.ticket_display_side_panel {
  max-width: 21.875rem;
  width: 33%;
  height: 12.25rem;
  border-radius: 0.625rem;
  padding: 1.25rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  align-items: center;
  text-align: center;
  &.share_side_panel {
    height: 142px;
  }
  #ticket_add_ppl_btn,
  #ticket_add_person_btn,
  #cancel_share_btn {
    &:disabled {
      background-color: #999;
      cursor: default;
      &:hover {
        background-color: #999;
      }
    }
    font-family: 'Proxima Nova';
    max-width: 19.375rem;
    width: 100%;
    height: 2.125rem;
    background: #ffffff;
    border: 2px solid #117bf7;
    border-radius: 5px;
    cursor: pointer;
    color: #117bf6;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
    &:hover {
      background-color: #ececec;
      color: #065b8d;
      border: 2px solid #065b8d;
    }
  }
  #ticket_add_ppl_btn:disabled,
  #ticket_add_person_btn:disabled {
    border: transparent;
    color: #ffffff;
  }
  .panel_hr {
    width: 1.5rem;
    border: 1px solid #aba8a8;
  }
  #share_tix_btn {
    max-width: 19.375rem;
    width: 100%;
    height: 2.125rem;
    background: #117bf7;
    border-radius: 5px;
    border: none;
    padding: 0.625rem 3.75rem;
    color: #ffffff;
    cursor: pointer;
    &:disabled {
      background: #999;
      cursor: default;
      &:hover {
        background-color: #999;
      }
    }
    &:hover {
      background-color: #065b8d;
    }
  }
  &.manage_list_side_panel,
  &.recall_tix_side_panel {
    justify-content: center;
    height: fit-content;
    #remove_tix_selected_btn,
    #recall_tix_btn {
      max-width: 19.375rem;
      width: 100%;
      height: 2.125rem;
      background: #ffffff;
      border-radius: 5px;
      border: 2px solid #df0303;
      color: #df0303;
      cursor: pointer;
      &:disabled {
        color: #999999;
        border: 2px solid #999999;
        background: #ffffff;
        cursor: default;
        &:hover {
          border: 2px solid #999999;
          background: #ffffff;
          color: #999999;
        }
      }
      &:hover {
        color: #900000;
        background: #ececec;
        border: 2px solid #910000;
      }
    }
  }
  .auto_save_timer {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #aba8a8;
  }
  @media (max-width: $dekstop_breakpoint) {
    width: 35%;
    padding: 2%;
    #ticket_add_ppl_btn,
    #ticket_add_person_btn,
    #share_tix_btn {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  @media (max-width: $rare_laptop_breakpoint) {
    width: 100%;
    max-width: 100%;
    left: 0;
    background: #ffffff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    display: block;
    height: 5.813rem;
    padding: 0.838rem 0.838rem 1.25rem;
    text-align: center;
    position: fixed;
    bottom: 0;
    z-index: 9;
    #ticket_add_person_btn,
    #cancel_share_btn {
      color: #117bf6;
      width: unset;
      max-width: unset;
      padding: 0.625rem 1.625rem;
      background: #ffffff;
      border: 2px solid #117bf7;
      border-radius: 5px;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 0.875rem;
      font-family: 'Proxima Nova';
      margin-right: 0.625rem;
      &:hover {
        cursor: pointer;
        background-color: #ececec;
        color: #065b8d;
        border: 2px solid #065b8d;
      }
    }
    #ticket_add_person_btn:disabled {
      border: transparent;
      color: #ffffff;
      background-color: #999;
      cursor: default;
      &:hover {
        background-color: #999;
      }
    }
    #share_tix_btn {
      background: #117bf7;
      width: unset;
      vertical-align: top;
      max-width: unset;
      border-radius: 5px;
      padding: 0.625rem 60px;
      border: none;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 0.875rem;
      color: #ffffff;
      font-family: 'Proxima Nova';
      &:disabled {
        background: #999;
        cursor: default;
        &:hover {
          background-color: #999;
        }
      }
      &:hover {
        background-color: #065b8d;
      }
    }
    #ticket_add_ppl_btn {
      display: none;
    }
    .panel_hr {
      display: none;
    }
    .auto_save_timer {
      display: block;
      margin-top: 10px;
    }
  }
  @media (max-width: $rare_mobile_breakpoint) {
    padding: 0.8rem 0.8rem 1rem;
    height: 5.3rem;
    #ticket_add_ppl_btn,
    #ticket_add_person_btn,
    #share_tix_btn {
      padding: 0.625rem 0.938rem !important;
    }
  }
}
