.nav_bar {
  background-color: #1c1c1c;
  width: 100%;
  color: #ffffff;
  height: 62px;
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 15px;
  h3 {
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #f7f7f7;
    font-weight: 400;
    cursor: pointer;
  }
  .navbar_logo {
    transform: translateY(2px);
  }
}
