.handsontable.htColumnHeaders {
  .htCore {
    thead th {
      font-family: 'Proxima Nova';
      background-color: #d3d3d3;
      &:first-child {
        border-radius: 0.25rem 0 0 0;
      }
      &:nth-child(5) {
        border-radius: 0 0.25rem 0 0;
      }
      &:last-child {
        border-radius: 0 0.25rem 0 0;
      }
      .relative {
        border-bottom: 0.0625rem solid #6a6a6a;
        span.colHeader {
          font-weight: 700;
          font-size: 1rem;
          line-height: 1rem;
          color: #333333;
        }
      }
      &:nth-child(6) {
        display: none;
      }
    }
    td {
      font-family: 'Proxima Nova';
      height: 1.5rem;
      text-align: left;
      &.centeredText {
        text-align: center;
      }
    }
    .htReadOnly {
      background: #f3f3f7;
      text-align: center;
      color: #000000;
    }
    .hidden {
      display: none;
    }
  }
  .ht_master {
    .wtHolder {
      border-radius: 0.25rem;
      .wtHider {
        min-height: 22.5rem;
        padding-bottom: 2.5rem;
        background-color: #d3d3d3;
        border-radius: 0.25rem;
      }
    }
  }
}

.hot-tooltip {
  height: 1.875rem;
  color: #fff;
  background: #646464;
  padding: 0.3125rem;
  z-index: 200;
  position: absolute;
  visibility: hidden;
  border-radius: 0.1875rem;
  white-space: nowrap;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -0.3125rem;
    width: 0;
    height: 0;
    border-top: solid 0.3125rem #646464;
    border-left: solid 0.3125rem transparent;
    border-right: solid 0.3125rem transparent;
  }
}
