@import '../../sass/base.scss';

.add_person_form,
.man_alloc_form,
.invalid_email_form {
  padding-bottom: 3px;
  .input_cont {
    margin-bottom: 0.9375rem;
    .person_modal_label,
    .man_alloc_modal_label,
    .invalid_email_modal_label {
      display: block;
      float: left;
      color: #333333;
      margin-bottom: 0.75rem;
      &.error {
        color: #df0303;
      }
    }
    .input_wrapper {
      display: inline-block;
      position: relative;
      width: 100%;
      .validationError {
        position: absolute;
        right: 0.625rem;
        top: 0.625rem;
      }
      .person_modal_input,
      .man_alloc_modal_input,
      .invalid_email_modal_input {
        width: 100%;
        height: 2.375rem;
        background: #ffffff;
        border: 1px solid #ced4da;
        border-radius: 4px;
        padding: 1% 2%;
        font-size: 1rem;
        &.error {
          border-color: #df0303;
        }
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .form_error_message {
      font-size: 1rem;
      line-height: 1rem;
      color: #df0303;
      display: block;
      text-align: start;
      text-shadow: 0px 1px 0px #ffffff;
      margin-top: 0.938rem;
    }
    @media (max-width: $rare_mobile_breakpoint) {
      .person_modal_label {
        font-size: 0.85rem;
        margin-bottom: 0.3rem;
      }
    }
  }
  .form_footer {
    button#remove_recipient_btn:disabled,
    button#save_and_view_btn:disabled {
      color: #999999;
      border: 2px solid #999999;
      cursor: not-allowed;
    }
    button#remove_recipient_btn:disabled:hover {
      background-color: #ffffff;
      color: #999999;
      border: 2px solid #999999;
    }
    button#save_recipient_changes_btn:disabled,
    button#save_and_add_btn:disabled,
    button#man_alloc_save_btn:disabled {
      background: #999999;
      color: #ffffff;
      cursor: not-allowed;
    }
  }
}
