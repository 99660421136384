.navbar_menu {
  position: fixed;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-out;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  .menu_items {
    max-width: 374px;
    height: 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    padding: 4px 6px;
    transition: 0.3s ease-out;
    overflow: hidden;
    .events_page_btn_container,
    .logout_btn_container {
      border-radius: 5px;
      padding-left: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      transition: 0.5s;
      .logout_btn,
      #events_page_link {
        border: 0;
        background: transparent;
        font-size: 1.125rem;
        line-height: 1.125rem;
        padding: 0;
        font-weight: 500;
        font-family: 'Proxima Nova';
        cursor: pointer;
        color: #111111;
      }
      #logout-button {
        width: 100%;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &:hover {
        background-color: #ebebeb;
      }
    }
    .navbar_menu_line {
      margin: 0.25rem -0.375rem;
      border: 0.063rem solid #b9b9b9;
    }
  }
  &.logout_opened {
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 1;
    z-index: 3;
    visibility: visible;
    .logout_dialogue {
      height: 5.688rem;
    }
  }
}
