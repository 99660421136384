[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change ./fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-ticketfan {
  font-size: 48px;
}

.icon-pencil:before {
  content: '\270f';
}
.icon-star:before {
  content: '\e006';
}
.icon-ok:before {
  content: '\e013';
}
.icon-remove:before {
  content: '\e014';
}
.icon-cog:before {
  content: '\e019';
}
.icon-trash:before {
  content: '\e020';
}
.icon-refresh:before {
  content: '\e031';
}
.icon-chevron-left:before {
  content: '\e079';
}
.icon-chevron-right:before {
  content: '\e080';
}
.icon-chevron-up:before {
  content: '\e113';
}
.icon-chevron-down:before {
  content: '\e114';
}
.icon-shopping-cart:before {
  content: '\e116';
}
.icon-menu-hamburger:before {
  content: '\e236';
}
.icon-triangle-left:before {
  content: '\e251';
}
.icon-triangle-right:before {
  content: '\e250';
}
.icon-triangle-bottom:before {
  content: '\e252';
}
.icon-triangle-top:before {
  content: '\e253';
}
.icon-bin:before {
  content: '\e900';
}
.icon-split:before {
  content: '\e901';
}
.icon-more-details:before {
  content: '\e902';
}
.icon-ada:before {
  content: '\e903';
}
.icon-minus:before {
  content: '\e904';
}
.icon-plus:before {
  content: '\e905';
}
.icon-reset:before {
  content: '\e906';
}
.icon-coup:before {
  content: '\e907';
}
.icon-ticket-flip:before {
  content: '\e908';
}
.icon-unlocked:before {
  content: '\e909';
}
.icon-chair:before {
  content: '\e90b';
}
.icon-search-settings:before {
  content: '\e90c';
}
.icon-seatmap:before {
  content: '\e90d';
}
.icon-locate:before {
  content: '\e90e';
}
.icon-edit:before {
  content: '\e90f';
}
.icon-event-info:before {
  content: '\e910';
}
.icon-section-list:before {
  content: '\e911';
}
.icon-section-venue:before {
  content: '\e912';
}
.icon-calendar:before {
  content: '\e109';
}
.icon-calendar-icon:before {
  content: '\e913';
}
.icon-ticketfan:before {
  content: '\e914';
}
.icon-select-triangle:before {
  content: '\e915';
}
.icon-exchange-in-out:before {
  content: '\e916';
}
.icon-exchange-in:before {
  content: '\e917';
}
.icon-exchange-out:before {
  content: '\e918';
}
.icon-add-outline:before {
  content: '\e919';
}
.icon-on-btn:before {
  content: '\e91a';
}
.icon-off-btn:before {
  content: '\e91b';
}
.icon-map-expand:before {
  content: '\e91c';
}
.icon-map-minimize:before {
  content: '\e91d';
}
.icon-map-nav-min:before {
  content: '\e91e';
}
.icon-map-pin:before {
  content: '\e91f';
}
.icon-adjust-huener111:before {
  content: '\e984';
}
.icon-copy:before {
  content: '\e999';
}
.icon-locked:before {
  content: '\e90a';
}
.icon-external-link-alt:before {
  content: '\e920';
}
.icon-cart-timer:before {
  content: '\e921';
}
