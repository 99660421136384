@import '../../../../sass/base';
.event_card_header {
  position: relative;
  color: #ffffff;
  height: 100%;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  background-color: rgba(0, 0, 0);
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  &:focus {
    outline-offset: 0.1875rem;
  }
  @media (max-width: $tablet_breakpoint) {
    .event_teams {
      top: 1rem;
    }
  }
  .team_color_overlay {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    opacity: 0.1;
  }
  .event_date {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
  }
  .event_teams {
    position: absolute;
    top: 1.25rem;
    left: 0.9375rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-family: 'Proxima Nova Bold';
  }
  .event_header_bottom {
    position: absolute;
    display: flex;
    justify-content: space-between;
    right: 1rem;
    left: 1rem;
    bottom: 1rem;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    font-weight: 600;
    @media (max-width: $tablet_breakpoint) {
      bottom: 0.6875rem;
      left: 0.9375rem;
      h3 {
        font-size: 0.95rem;
      }
    }
    .event_ticket_numbers {
      color: #fafafa;
    }
    .event_stadium {
      @media (min-width: $tablet_breakpoint) {
        align-self: flex-end;
      }
    }
    b {
      font-size: 1.4375rem;
      line-height: 1.4375rem;
    }
    &:not(b) {
      letter-spacing: 0.0277rem;
    }
  }
  .event_home_team_logo {
    position: absolute;
    top: 0.9375rem;
    right: 1rem;
    img {
      width: 2.75rem;
    }
  }
  .event_processing_text {
    font-size: 1.0625rem;
    line-height: 1.0625rem;
  }
}
